@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  button:focus {
    outline: 0;
  }

  .imgTint:hover {
      filter: brightness(75%);
  }

  .imgTint:focus {
    filter: brightness(50%);
  }

  .outline {
    border: solid white 3px;
    border-radius: 15px;
  }

  .outlineThin {
    border: solid white 1px;
    border-radius: 15px;
  }

  .fadeIn {
    animation-duration: 0.5s;
    animation-name: fadein;
  }

  .fadeOut {
    animation-duration: 0.5s;
    animation-name: fadeout;
  }

  @keyframes fadein {
    from {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }
  
    to {
      display: block; 
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      display: block;
      opacity: 1;
    }
  
    to {
      display: none; 
      opacity: 0;
    }
  }
}